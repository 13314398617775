.button,
button {
  display: inline-block;
  text-decoration: none;
  height: 48px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
  width: 300px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.1px;
  white-space: wrap;
  border-radius: 8px;
  cursor: pointer;
}
button:hover,
.button:focus {
  color: #333;
  border-color: #888;
  outline: 0;
}
.button.button-primary {
  color: #fff;
  filter: brightness(90%);
}
.button.button-primary:hover,
.button.button-primary:focus {
  color: #fff;
  filter: brightness(90%);
}

.icon {
  padding: 0px 8px 3.5px 0px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.button.button-default {
  color: #ffffff;
  background-color: #0085ff;
}
.button.button-default:hover,
.button.button-default:focus {
  filter: brightness(90%);
}

/* Github */
.button.button-github {
  color: #ffffff;
  background-color: #000000;
  border: 1px solid #ffffff;
}
.button.button-github:hover,
.button.button-github:focus {
  filter: brightness(90%);
}

/* Twitter */
.button.button-twitter {
  color: #ffffff;
  background-color: #1da1f2;
}
.button.button-twitter:hover,
.button.button-twitter:focus {
  filter: brightness(90%);
}

/* Instagram */
.button.button-instagram {
  color: #ffffff;
  background-image: linear-gradient(-135deg, #1400c8, #b900b4, #f50000);
}
.button.button-instagram:hover,
.button.button-instagram:focus {
  filter: brightness(90%);
}

/* Facebook */
.button.button-facebook {
  color: #ffffff;
  background-color: #1877f2;
}
.button.button-facebook:hover,
.button.button-facebook:focus {
  filter: brightness(90%);
}

/* LinkedIn */
.button.button-linkedin {
  color: #ffffff;
  background-color: #2867b2;
}
.button.button-linkedin:hover,
.button.button-linkedin:focus {
  filter: brightness(90%);
}

/* Xing */
.button.button-xing {
  color: #ffffff;
  background-color: #026466;
}
.button.button-xing:hover,
.button.button-xing:focus {
  filter: brightness(90%);
}
